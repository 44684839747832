import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Component
import FilterSuggestionByKey from "./component";

// Actions
import { fetchFilters, setSearchResultTrackingData, clearAllFilters } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        appliedFilters
    },
    cities: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName
        } = {}
    }
}) => ({
    selectedCityCode,
    selectedCityName,
    allFilters,
    appliedFilters
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    reloadCarListConnect: reloadCarList,
    fetchFiltersConnect: fetchFilters,
    clearAllFiltersConnect: clearAllFilters,
    setSearchResultTrackingDataConnect: setSearchResultTrackingData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterSuggestionByKey);
