/* eslint-disable no-nested-ternary */
/* eslint-disable max-params */
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import SlickWrapper from "./../../shared/slick-wrapper";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Component
import ButtonImage from "../../shared/button-image";
const ListTab = loadable(() => import("../../shared/list-tab"));

// Images
import DefaultMakeBodyTypeLogo from "../../shared/images/default-make-bodytype.svg";
import IconForward from "../../shared/images/icons/forward-icon-orange.svg";

// Constants
import { getPopularBrandUrl } from "../../../utils/helpers/cdn-image-url";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { FILTER_DESCRIPTION_MAP, CATEGORY_EVENT_MAP, FILTER_SUGGESTION_HEADING, SEARCH_MODAL_CATEGORY_EVENT_MAP } from "./constant";
import { DEFAULT_AE_CITY_NAME, NUMBER } from "../../../constants/app-constants";
import { defaultFilterPayload } from "../car-listing/constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

// Utilities
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import Filters from "../../../utils/filters-v2";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import saveSession from "../../../utils/helpers/save-session";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread.js";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations.js";

const SLIDER_SETTINGS = {
    slidesToShow: NUMBER.FIVE,
    slidesToScroll: NUMBER.FOUR,
    infinite: false,
    arrow: false,
    dots: true,
    touchThreshold: NUMBER.TEN
};

const SLIDER_SETTINGS_BUDGET = {
    slidesToShow: NUMBER.TWO,
    slidesToScroll: NUMBER.ONE,
    infinite: false,
    arrow: false,
    dots: true,
    touchThreshold: NUMBER.TEN
};

const lazyLoadFilterKeys = [ FILTER_ENTITIES.MAKE, FILTER_ENTITIES.BODY_TYPE];

const FilterSuggestionByKey = ({
    source = "",
    title = "",
    filterKey = "MAKE",
    allFilters = {},
    isFromSearchModal = false,
    toggelSearchBarModal = () => {},
    fetchFiltersConnect,
    clearAllFiltersConnect,
    setSearchResultTrackingDataConnect,
    selectedCityCode,
    selectedCityName
}) => {
    const [showAllMake, setShowAllMake] = React.useState(false);

    const { customPush } = useCustomHistoryOperations();

    useEffect(() => {
        if (Object.keys(allFilters?.make || {})?.length === NUMBER.ZERO) {
            try {
                const filterParams = {
                    variant: "filterV5"
                };
                fetchFiltersConnect(filterParams);
            } catch (err) {
                // Handle if any error
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFilters]);

    const fetchSeoUrl = (data) => {
        const filtersObj = new Filters({});
        return filtersObj.getRelativeURL([filterKey], data);
    };

    const onFilterOptionClick = async (url, option) => {
        await yieldToMainThread();
        clearAllFiltersConnect();
        setSearchResultTrackingDataConnect({ filterType: "Homepage" });
        if (isFromSearchModal) {
            trackPlatformCustomEventsAEV2(SEARCH_MODAL_CATEGORY_EVENT_MAP[filterKey], { eventCategory: `Buy_used_cars_B2C_${source === ROUTE_NAME.LISTING ? "Listing" : "Home"}_Page` });
        } else {
            trackPlatformCustomEventsAEV2(CATEGORY_EVENT_MAP[filterKey], { eventLabel: option || "NA" });
        }
        await customPush(url);
        await yieldToMainThread();
        toggelSearchBarModal();
        scrollToTop();
    };

    const handleAllBrandsClick = async () => {
        await yieldToMainThread();
        clearAllFiltersConnect();
        setSearchResultTrackingDataConnect({ filterType: "Homepage" });
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL } = filters.getListingPayload({ ...defaultFilterPayload });
        await customPush(`${relativeURL}&source=makeFilter`);
        toggelSearchBarModal();
    };

    const renderImageButton = (filter, optionCards, { imageMapping = {}, defaultImage }) => (
        // eslint-disable-next-line complexity
        (optionCards || []).map((item, index) => {
            const url = fetchSeoUrl(item);
            const displayTextLabel = `${filter?.key === FILTER_ENTITIES.MAKE ? "Used <br/>" : ""}${item?.key === "MERCEDES BENZ" ? "Mercedes" : item?.displayText?.length === NUMBER.THREE ? item?.displayText?.toUpperCase() : item?.displayText?.toLowerCase()}`;
            return (
                <a key={index}
                    href={url}
                    onClick={(e) => { e.preventDefault(); }}
                    styleName={`styles.bodyTypeBlock ${filter?.key === FILTER_ENTITIES.MAKE ? "styles.setHeight" : ""}`}
                    title={`Buy Used ${item.displayText} cars in ${DEFAULT_AE_CITY_NAME} - UAE`}
                >
                    <ButtonImage
                        onClick={onFilterOptionClick.bind(null, url, item.displayText)}
                        data={{
                            text: displayTextLabel,
                            imgSrc: filter?.key === FILTER_ENTITIES.MAKE ?
                                getPopularBrandUrl(item?.key) || DefaultMakeBodyTypeLogo :
                                imageMapping[item?.key] || defaultImage || DefaultMakeBodyTypeLogo,
                            height: filter?.key === FILTER_ENTITIES.MAKE ? "40" : "",
                            width: filter?.key === FILTER_ENTITIES.MAKE ? "40" : "50",
                            showLazy: lazyLoadFilterKeys.includes(filter?.key),
                            imgAltTag: `Buy Used ${item.displayText} cars in ${DEFAULT_AE_CITY_NAME} - UAE`
                        }}
                    />
                </a>
            );
        })
    );

    const handleMoreClick = () => {
        setShowAllMake(true);
    };

    const renderAllBrands = () => {
        return (
            <span styleName={"styles.bodyTypeBlock styles.setHeight"}>
                <ButtonImage
                    onClick={handleAllBrandsClick}
                    data={{
                        text: "All Used <br/> Car Brands",
                        imgSrc: DefaultMakeBodyTypeLogo,
                        width: "40",
                        height: "40",
                        showLazy: false
                    }}
                />
            </span>
        );
    };

    const renderMore = () => {
        return (
            <span styleName={"styles.bodyTypeBlock styles.moreWrap"}>
                <span onClick={handleMoreClick}>MORE <img src={IconForward} width="7" height="13" alt="" loading="lazy" /></span>
            </span>
        );
    };

    const renderListTab = (filter) => (
        (filter.suggestions || []).map((item, index) => {
            const url = fetchSeoUrl(item);
            const handleClick = async () => {
                await yieldToMainThread();
                saveSession("toggleEmi", false);
                onFilterOptionClick(url, item.displayText);
            };
            return (
                <a
                    key={`tab_list_${index}`}
                    href={url}
                    onClick={(e) => { e.preventDefault(); }}
                >
                    <ListTab
                        onClick={handleClick}
                        data={item}
                        dataKey="key"
                        labelKey="displayText" />
                </a>
            );
        })
    );

    const renderList = (selectedFilter) => {
        let component = null;
        if (FILTER_DESCRIPTION_MAP[filterKey].renderType === "image-button") {
            const optionCards = filterKey === FILTER_ENTITIES.MAKE && !showAllMake ?
            selectedFilter?.options?.slice(NUMBER.ZERO, NUMBER.SEVEN) : filterKey === FILTER_ENTITIES.MAKE && showAllMake ?
            selectedFilter?.options?.slice(NUMBER.ZERO, NUMBER.SIXTEEN) : selectedFilter?.options;
            component = (
                <div styleName="styles.catagoryBodyType">
                    <SlickWrapper {...SLIDER_SETTINGS} >
                        {filterKey === FILTER_ENTITIES.MAKE && renderAllBrands()}
                        {renderImageButton(selectedFilter, optionCards, FILTER_DESCRIPTION_MAP[filterKey])}
                        {filterKey === FILTER_ENTITIES.MAKE && !showAllMake && renderMore()}
                    </SlickWrapper>
                </div>
            );
        } else if (FILTER_DESCRIPTION_MAP[filterKey].renderType === "list") {
            component = (
                <div styleName={"styles.catagoryPrice"}>
                    <SlickWrapper {...SLIDER_SETTINGS_BUDGET} >
                        {renderListTab(selectedFilter)}
                    </SlickWrapper>

                </div>
            );
        }
        return component;
    };

    const renderData = useMemo(() => {
        const selection = allFilters[filterKey] || {};
        let component = null;
        component = renderList(selection);
        return component;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFilters, showAllMake]);

    return (
        <div styleName={"styles.wrapper"} className="newUserWrap">
            {(FILTER_SUGGESTION_HEADING[filterKey] || title) && (
                <h2 styleName={"styles.sectionHeading"}>
                    {title ? title : FILTER_SUGGESTION_HEADING[filterKey]}
                </h2>
            )}
            {renderData}
        </div>
    );
};

FilterSuggestionByKey.propTypes = {
    source: PropTypes.string,
    isFromSearchModal: PropTypes.string,
    title: PropTypes.string,
    filterKey: PropTypes.string,
    allFilters: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    fetchFiltersConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    setSearchResultTrackingDataConnect: PropTypes.func,
    toggelSearchBarModal: PropTypes.func,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string
};

export default FilterSuggestionByKey;
