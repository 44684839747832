// Images
import Convertible from "../../shared/images/filter-body-type/convertible.png";
import SingleCabUtility from "../../shared/images/filter-body-type/single-utility.png";
import CrewCabUtility from "../../shared/images/filter-body-type/crew-cab.png";
import Coupe from "../../shared/images/filter-body-type/coupe.png";
import Hatchback from "../../shared/images/filter-body-type/hatchback.png";
import Minivan from "../../shared/images/filter-body-type/minivan.png";
import Mpv from "../../shared/images/filter-body-type/mpv.png";
import Sedan from "../../shared/images/filter-body-type/sedan.png";
import Suv from "../../shared/images/filter-body-type/suv.png";
import Truck from "../../shared/images/filter-body-type/truck.png";
import Wagon from "../../shared/images/filter-body-type/wagon.png";
import Crossover from "../../shared/images/filter-body-type/crossover.svg";
import LuxurySedan from "../../shared/images/filter-body-type/luxury-sedan.svg";
import Pickup from "../../shared/images/filter-body-type/pickup.svg";
import SuvCoupe from "../../shared/images/filter-body-type/suv-coupe.png";
import SportBack from "../../shared/images/filter-body-type/sportback.svg";
import DoubleCabUtility from "../../../utils/image-mapping/images/doubleCabUtility.svg";

// Constant
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";
import { uncategorizedEvents } from "../../../tracking/msite/uncategorized-events";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";

export const FILTER_DESCRIPTION_MAP = {
    [FILTER_ENTITIES.BODY_TYPE]: {
        renderType: "image-button",
        imageMapping: {
            "CONVERTIBLE": Convertible,
            "COUPE": Coupe,
            "HATCHBACK": Hatchback,
            "MINIVAN": Minivan,
            "MPV": Mpv,
            "SEDAN": Sedan,
            "SUV": Suv,
            "TRUCK": Truck,
            "WAGON": Wagon,
            Crossover,
            LuxurySedan,
            "PICKUP": Pickup,
            "SUV COUPE": SuvCoupe,
            "SPORTBACK": SportBack,
            "DOUBLE CAB UTILITY": DoubleCabUtility,
            "CREW CAB UTILITY": CrewCabUtility,
            "SINGLE CAB UTILITY": SingleCabUtility
        },
        defaultImage: Sedan
    },
    [FILTER_ENTITIES.MAKE]: {
        renderType: "image-button"
    },
    [FILTER_ENTITIES.PRICE]: {
        renderType: "list"
    },
    [FILTER_ENTITIES.FUEL_TYPE]: {
        renderType: "list"
    },
    [FILTER_ENTITIES.OWNERS]: {
        renderType: "list"
    }
};

export const FILTER_SUGGESTION_HEADING = {
    [FILTER_ENTITIES.MAKE]: "",
    [FILTER_ENTITIES.PRICE]: "Used cars for sale by Price",
    [FILTER_ENTITIES.BODY_TYPE]: "Second Hand cars by Body type"
};

export const CATEGORY_EVENT_MAP = {
    [FILTER_ENTITIES.BODY_TYPE]: b2cHomePageEvents.homePageTopBodytype,
    [FILTER_ENTITIES.MAKE]: b2cHomePageEvents.homePagePopularBrand,
    [FILTER_ENTITIES.PRICE]: b2cHomePageEvents.homePageBudgetFriendly
};

export const SEARCH_MODAL_CATEGORY_EVENT_MAP = {
    [FILTER_ENTITIES.BODY_TYPE]: uncategorizedEvents.topBodyTypeModal,
    [FILTER_ENTITIES.PRICE]: uncategorizedEvents.budgetFriendlyModal
};
